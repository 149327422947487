// Core
import { Fragment, lazy, type PropsWithChildren, Suspense } from "react";

// Utils
import { MODALS_BOOK } from "@/client/definitions/modals";

export type ModalViewBaseProps = {
  onClose?: () => void;
};

type ModalViewsSwitcherProps = PropsWithChildren<
  ModalViewBaseProps & {
    name: string;
    params?: {
      id?: number;
    };
  }
>;

const ModalCallBack = lazy(
  () => import("@/client/domains/landing/callback/components/callback-modal"),
);
const ModalCallBackLimit = lazy(
  () => import("@/client/domains/landing/callback/components/callback-limit-modal"),
);
const ModalCallBackPersonalLimit = lazy(
  () => import("@/client/domains/landing/callback/components/callback-personal-limit-modal"),
);
const ModalSignupPassword = lazy(
  () => import("@/client/domains/signup/signup-profile/components/signup-password-modal"),
);
const ModalSignupSuccess = lazy(
  () => import("@/client/domains/signup/signup-profile/components/signup-success-modal"),
);
const ModalNavbarUserSignout = lazy(
  () => import("@/client/domains/user/navigation/components/navbar-user-signout-modal"),
);
const ModalMassSearchInstructions = lazy(
  () => import("@/client/domains/dashboard/components/modal-mass-search-instructions"),
);
const ModalForgotPasswordEmail = lazy(
  () => import("@/client/domains/auth/forgot-password/components/forgot-password-email-modal"),
);
const ModalBasketVatServiceUnavailable = lazy(
  () => import("@/client/domains/basket/components/basket-vat-service-unavailable-modal"),
);
const ModalBasketVatInvalid = lazy(
  () => import("@/client/domains/basket/components/basket-vat-invalid-modal"),
);

export const ModalViewsSwitcher = (props: ModalViewsSwitcherProps) => {
  const { name, onClose } = props;

  switch (name) {
    case MODALS_BOOK.callback: {
      return (
        <Suspense>
          <ModalCallBack />
        </Suspense>
      );
    }
    case MODALS_BOOK.callbackLimit: {
      return (
        <Suspense>
          <ModalCallBackLimit onClose={onClose} />
        </Suspense>
      );
    }
    case MODALS_BOOK.callbackPersonalLimit: {
      return (
        <Suspense>
          <ModalCallBackPersonalLimit />
        </Suspense>
      );
    }
    case MODALS_BOOK.passwordCreate: {
      return (
        <Suspense>
          <ModalSignupPassword />
        </Suspense>
      );
    }
    case MODALS_BOOK.signupSuccess: {
      return (
        <Suspense>
          <ModalSignupSuccess onClose={onClose} />
        </Suspense>
      );
    }
    case MODALS_BOOK.signOutConfirm: {
      return (
        <Suspense>
          <ModalNavbarUserSignout onClose={onClose} />
        </Suspense>
      );
    }
    case MODALS_BOOK.massSearchInstructions: {
      return (
        <Suspense>
          <ModalMassSearchInstructions />
        </Suspense>
      );
    }
    case MODALS_BOOK.forgotPassword: {
      return (
        <Suspense>
          <ModalForgotPasswordEmail onClose={onClose} />
        </Suspense>
      );
    }
    case MODALS_BOOK.vatServiceUnavailable: {
      return (
        <Suspense>
          <ModalBasketVatServiceUnavailable />
        </Suspense>
      );
    }
    case MODALS_BOOK.vatInvalid: {
      return (
        <Suspense>
          <ModalBasketVatInvalid onClose={onClose} />
        </Suspense>
      );
    }
    default:
      return <Fragment />;
  }
};
