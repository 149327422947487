// Core
import { PropsWithChildren } from "react";
import cx from "classnames";

// Components
import { Skeleton } from "antd";

// Utils
import st from "./styles.module.css";

type TextAlignmentVerticalType = "" | "top" | "middle" | "bottom" | "baseline";
type TextAlignmentHorizontalType = "" | "left" | "center" | "right";
export type TextSizeType =
  | "12"
  | "14"
  | "16"
  | "20"
  | "22"
  | "24"
  | "28"
  | "32"
  | "36"
  | "38"
  | "44";
type TextSkeletonWidthType = "10" | "12" | "14" | "16" | "18" | "20" | "full";
type TextFontType = "system" | "base" | "second";
type TextTransformType = "up" | "low" | "cap" | "revert";
type TextFontWeightType =
  | "100"
  | "200"
  | "300"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900"
  | "inherit";
type TextFontStyleType = "italic";
type TextDecorationType = "line-through";
type TextColorWhiteType = "white-100";
type TextColorGreyType =
  | "gray-200"
  | "gray-300"
  | "gray-400"
  | "gray-500"
  | "gray-600"
  | "gray-650"
  | "gray-700"
  | "gray-900"
  | "gray-950"
  | "gray-1000"
  | "dark";
type TextColorMainGrayType = "main-gray-3" | "main-gray-6" | "gray-6";
type TextColorOrangeType = "orange-800" | "orange-900";
type TextColorBlueType = "blue-700" | "blue-750" | "blue-800";
type TextColorDangerType = "cta-12";
type TextColorType =
  | TextColorWhiteType
  | TextColorGreyType
  | TextColorBlueType
  | TextColorMainGrayType
  | TextColorDangerType
  | TextColorOrangeType
  | "inherit";
type TextWrapType = "nowrap";

export type TypographyTextProps = PropsWithChildren<{
  loading?: boolean;
  tag?: "span" | "div" | "p";
  alignmentVertical?: TextAlignmentVerticalType;
  alignmentHorizontal?: TextAlignmentHorizontalType;
  transform?: TextTransformType;
  size?: TextSizeType;
  skeletonWidth?: TextSkeletonWidthType;
  color?: TextColorType;
  font?: TextFontType;
  fontWeight?: TextFontWeightType;
  fontStyle?: TextFontStyleType;
  box?: "full" | "inline";
  testId?: string;
  className?: string;
  decoration?: TextDecorationType;
  wrap?: TextWrapType;
}>;

export const Text = (props: TypographyTextProps) => {
  const {
    tag: Element = "span",
    size = "14",
    font = "",
    fontWeight = "",
    fontStyle = "",
    color,
    alignmentVertical = "",
    skeletonWidth = "full",
    alignmentHorizontal = "",
    transform = "",
    box = "",
    className = "",
    testId,
    children = "",
    loading,
    decoration = "",
    wrap = "",
  } = props;

  const textStyles = cx(className, st.text, {
    [st[`text-font-${font}`]]: Boolean(font),
    [st[`text-font-weight-${fontWeight}`]]: Boolean(fontWeight),
    [st[`text-font-style-${fontStyle}`]]: Boolean(fontStyle),
    [st[`text-size-${size}`]]: Boolean(size),
    [st[`text-color-${color}`]]: Boolean(color),
    [st[`text-box-${box}`]]: Boolean(box),
    [st[`text-alignment-vertical-${alignmentVertical}`]]: Boolean(alignmentVertical),
    [st[`text-alignment-horizontal-${alignmentHorizontal}`]]: Boolean(alignmentHorizontal),
    [st[`text-transform-${transform}`]]: Boolean(transform),
    [st[`text-decoration-${decoration}`]]: Boolean(decoration),
    [st[`text-wrap-${wrap}`]]: Boolean(wrap),
  });
  const skeletonStyles = cx(st["text-skeleton"], {
    [st[`text-skeleton-height-${size}`]]: Boolean(size),
    [st[`text-skeleton-width-${skeletonWidth}`]]: Boolean(skeletonWidth),
  });

  if (loading) {
    return <Skeleton.Input active className={skeletonStyles} />;
  }

  return (
    <Element data-testid={testId} className={textStyles}>
      {children}
    </Element>
  );
};
