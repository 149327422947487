// Core
import z from "zod";

// Definitions
import { ValueOf } from "@/common/types/basic";
import { selectItemSchemeNullable } from "@/client/components/ui/FormFields/InputSelect/InputSelect.types";

export const UserCountryCodes = {
  gb: "gb",
  fr: "fr",
  de: "de",
  it: "it",
  pl: "pl",
} as const;

export const UserLanguagesEnum = {
  en: "en",
  fr: "fr",
  de: "de",
} as const;

export type UserCountryCodesType = ValueOf<typeof UserCountryCodes>;

export const userSchema = z.object({
  id: z.number(),
  customerId: z.number(),
  name: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
  phonePrefix: selectItemSchemeNullable,
  country: selectItemSchemeNullable,
});

export type UserType = z.infer<typeof userSchema>;
